import React from 'react';

import Layout from '../components/Layout';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import pic4 from '../assets/images/pic04.jpg';
import pic5 from '../assets/images/pic05.jpg';
import pic6 from '../assets/images/pic06.jpg';
import pic7 from '../assets/images/pic07.jpg';

import config from '../../config';
const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <div className="logo">
          <span className="icon fa-code"></span>
        </div>
        <h2>{config.heading}</h2>
        <p>
          {config.subHeading}
          <ul className="iconsheader">
            {config.socialLinks.map(social => {
              const { style, icon, name, url } = social;
              return (
                <li key={url}>
                  <a href={url} className={`icon ${style} ${icon}`}>
                    <span className="label">{name}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </p>
      </div>
    </section>

    <section id="wrapper">
      <section id="about" className="wrapper spotlight style1">
        <div className="inner">
          <img className="image" src={pic1} alt="" />

          <div className="content">
            <h2 className="major">About Me</h2>
            <p>
              I am <strong>David Uriel Guadarrama</strong>, a current freelance
              web developer that specializes in Front-end web development. At
              the moment, I am all about using the React library and building
              single-page React applications. I am very passionate about
              learning new developing skills and creating and working on new
              projects.
              <br />
              <br />I studied at San Diego State University, where I double
              majored in Business Administration, emphasis in Information
              Systems, and Psychology. While in school I discovered my passion
              for programming. As an Information Systems major, I had the unique
              opportunity to learn programming and apply it to real world
              business applications and use.
              <br />
              <br />
              When I'm not sharpening my web dev skills, you can usually find me
              playing guitar and recording music. I have a huge collection of
              electric guitars and am currently running out of room to store
              them.
            </p>
            {/* <a href="/#" className="special">
              Learn more
            </a> */}
          </div>
        </div>
      </section>

      <section id="education" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={pic2} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Education</h2>

            <h3>San Diego State University</h3>

            <p>
              BACHELOR OF ARTS • 2012 - 2016
              <br />
              <em>
                Business Administration, Information Systems <br />
                and Psychology
              </em>
            </p>

            <p></p>
            {/* <a href="/#" className="special">
              Learn more
            </a> */}
          </div>
        </div>
      </section>

      <section id="skills" className="wrapper spotlight style3">
        <div className="inner">
          <a href="/#" className="image">
            <img src={pic3} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Skills</h2>

            <div className="row">
              <div className="col-4 col-4-medium">
                <p>HTML5</p>
                <p>React</p>
                <p>Angular JS</p>
                <p>WordPress</p>
              </div>
              <div className="col-4 col-4-medium">
                <p>CSS3</p>
                <p>NodeJS</p>

                <p>jQuery</p>
                <p>Photoshop</p>
              </div>
              <div className="col-4 col-4-medium">
                <p>JavaScript</p>
                <p>Sass Bootstrap</p>
                <p>Git & GitHub</p>
                <p>Illustrator</p>
              </div>
            </div>
            {/* <a href="/#" className="special">
              Learn more
            </a> */}
          </div>
        </div>
      </section>

      <section id="projects" className="wrapper alt style1">
        <div className="inner">
          <h2 className="major">Projects</h2>
          <p>
            Here are some examples of my current projects that I have worked.
            You can view a working live demo and see the source code on GitHub.
          </p>
          <section className="features">
            <article>
              <a href="https://netflix.daviduriel.com" className="image">
                <img src={pic4} alt="" />
              </a>
              <h3 className="major">Netflix Clone</h3>
              <p>
                A Netflix clone built using React and Material-UI. This
                application uses OMDb API to obtain movie information and links
                to movie trailers from YouTube.
              </p>
              <a href="https://netflix.daviduriel.com" className="special">
                Live Demo
              </a>
              <a
                href="https://github.com/DaveUriel/netflix-clone"
                className="special"
              >
                GitHub
              </a>
            </article>
            <article>
              <a href="https://spotify.daviduriel.com" className="image">
                <img src={pic5} alt="" />
              </a>
              <h3 className="major">Spotify Clone</h3>
              <p>
                A Spotify clone built using React. This application uses Spotify
                Web API to obtain user information and playlists to show up on a
                mock Spotify web player.
              </p>
              <a href="https://spotify.daviduriel.com/" className="special">
                Live Demo
              </a>
              <a
                href="https://github.com/DaveUriel/spotify-clone-app"
                className="special"
              >
                GitHub
              </a>
            </article>
            <article>
              <a href="https://amazon.daviduriel.com" className="image">
                <img src={pic6} alt="" />
              </a>
              <h3 className="major">Amazon Clone</h3>
              <p>
                An Amazon clone built using React and Firebase. This application
                uses a NoSQL cloud database with Google Firebase to create a
                fully functional online store.
              </p>
              <a href="https://amazon.daviduriel.com" className="special">
                Live Demo
              </a>
              <a
                href="https://github.com/DaveUriel/amazon-clone"
                className="special"
              >
                GitHub
              </a>
            </article>
            <article>
              <a href="https://forestld.com" className="image">
                <img src={pic7} alt="" />
              </a>
              <h3 className="major">Forestld.com</h3>
              <p>
                A service-based business website built using WordPress. This
                WordPress site was built as a single-page and optimized for
                mobile.
              </p>
              <a href="https://forestld.com" className="special">
                Live Demo
              </a>
            </article>
          </section>
          {/* <ul className="actions">
            <li>
              <a href="/projects" className="button">
                Browse All
              </a>
            </li>
          </ul> */}
        </div>
      </section>
    </section>
  </Layout>
);

export default IndexPage;
